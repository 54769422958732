<!-- ---------- ---------- ---------- HTML Structure ---------- ---------- ---------- -->
<template>
  <div class="navcontainer">
    <!--Top Title of the app-->
    <!--Left Part-->
    <button class="navtab navtab1">
      <!--Todo: Nur Anzeigen, wenn bereits einmal navigiert wurde-->
      <div v-on:click="$router.go(-1)">
        <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="currentColor"
          class="bi bi-arrow-left-short" viewBox="0 0 16 16" color="white"
          v-if="$router.options.history.state.back != null">
          <!--          v-if="$router.options.history.state.position > 0"-->
          <path fill-rule="evenodd"
            d="M12 8a.5.5 0 0 1-.5.5H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5H11.5a.5.5 0 0 1 .5.5z" />
        </svg>
      </div>
    </button>

    <!--Middle Part-->
    <button class="navtab navtab2">
      <span v-if="$route.name != 'DiscoverView' && false" class="welcome">Timetip</span>
      <!--To enable the transition, delete the "Disabled" text below
      <transition name="onCountChangeDisabled" mode="out-in" v-if="false">
        <a
          id="activitycount"
          :key="$store.state.storeActivityListFiltered.length"
        >
          {{ $store.state.storeActivityListFiltered.length }} /
          {{ $store.state.storeActivityList.length }} activities</a
        >
      </transition>
      -->

      <div v-if="$route.name == 'DiscoverView'" class="comic">
        <div style="" class="dancing14">
          <span v-if="$store.state.user.countryCode == 'DE'">Timetip </span>
          <span v-if="$store.state.user.countryCode != 'DE'">Timetip </span>
        </div>
        <!-- <div  v-if="Object.keys(this.$store.state.clickedAnswers).length != 0" style="display: inline" v-on:click="$router.push('/activitylist')">
          <div style="display: inline-block; " class="">
            <span> {{ animatedNumber }}</span>
          </div>
          <div style="display: inline;" class="">
            <span v-if="$store.state.user.countryCode == 'DE'"> Aktivitäten </span>
            <span v-if="$store.state.user.countryCode != 'DE'"> activities </span>
          </div>
        </div> -->
      </div>

      <div v-if="$route.name == 'FilterView'" class="comic" style="display: inline"
        v-on:click="$router.push('/activitylist')">
        <span> {{ animatedNumber }}</span>
        <span v-if="$store.state.user.countryCode == 'DE'">
          Aktivitäten
        </span>
        <span v-if="$store.state.user.countryCode != 'DE'">
          activities
        </span>
      </div>
      <div v-if="$route.name == 'DetailsView'" class="comic" style="display: inline">
        <span v-if="$store.state.user.countryCode == 'DE'">
          {{ $store.state.storeActivityListFiltered.length }} Aktivitäten
        </span>
        <span v-if="$store.state.user.countryCode != 'DE'">
          {{ $store.state.storeActivityListFiltered.length }} activities
        </span>
      </div>
      <div v-if="$route.name == 'ActivityListView'" class="comic" style="display: inline">
        <span v-if="$store.state.user.countryCode == 'DE'">
          {{ $store.state.storeActivityListFiltered.length }} Aktivitäten
        </span>
        <span v-if="$store.state.user.countryCode != 'DE'">
          {{ $store.state.storeActivityListFiltered.length }} activities
        </span>
      </div>
      <div v-if="$route.name == 'FavoritesListView'" class="comic" style="display: inline">
        <span v-if="$store.state.user.countryCode == 'DE'">Favoriten</span>
        <span v-if="$store.state.user.countryCode != 'DE'">Favorites</span>
      </div>
      <div v-if="$route.name == 'SettingsView' && $store.state.dataLoaded" class="comic">
        <span v-if="$store.state.user.countryCode == 'DE'">Einstellungen</span>
        <span v-if="$store.state.user.countryCode != 'DE'">Settings</span>
      </div>
      <div v-if="
        $route.name == 'SignupWithEmailAndPassword' && $store.state.dataLoaded
      " class="comic">
        <span v-if="$store.state.user.countryCode == 'DE'">Registrieren</span>
        <span v-else>Sign up</span>
      </div>
      <div v-if="
        $route.name == 'LoginWithEmailAndPassword' && $store.state.dataLoaded
      " class="comic">
        <span v-if="$store.state.user.countryCode == 'DE'">Login</span>
        <span v-else>Login</span>
      </div>
      <div v-if="$route.name == 'Login' && $store.state.dataLoaded" class="comic">
        <span v-if="$store.state.user.countryCode == 'DE'">Login</span>
        <span v-else>Login</span>
      </div>
      <div v-if="$route.name == 'DeleteUser' && $store.state.dataLoaded" class="comic">
        <span>Account</span>
      </div>
      <div v-if="$route.name == 'PrivacyPolicy' && $store.state.dataLoaded" class="comic">
        <span v-if="$store.state.user.countryCode == 'DE'">Datenschutz</span>
        <span v-else>Privacy Policy</span>
      </div>
      <div v-if="$route.name == 'SiteNotice' && $store.state.dataLoaded" class="comic">
        <span v-if="$store.state.user.countryCode == 'DE'">Impressum</span>
        <span v-else>Site Notice</span>
      </div>
    </button>

    <!--Right Part-->
    <!-- goToListView -->
    <button class="navtab navtab3">
      <div v-if="$route.name == 'DetailsView'" v-on:click="$router.push('/activitylist')" class="svgcontainer">
        <svg xmlns="http://www.w3.org/2000/svg" height="20px" viewBox="0 0 24 24" width="20px" fill="#ffffff">
          <path d="M0 0h24v24H0V0z" fill="none" />
          <path
            d="M4 10.5c-.83 0-1.5.67-1.5 1.5s.67 1.5 1.5 1.5 1.5-.67 1.5-1.5-.67-1.5-1.5-1.5zm0-6c-.83 0-1.5.67-1.5 1.5S3.17 7.5 4 7.5 5.5 6.83 5.5 6 4.83 4.5 4 4.5zm0 12c-.83 0-1.5.68-1.5 1.5s.68 1.5 1.5 1.5 1.5-.68 1.5-1.5-.67-1.5-1.5-1.5zM7 19h14v-2H7v2zm0-6h14v-2H7v2zm0-8v2h14V5H7z" />
        </svg>
      </div>

      <!--goToDetailsView-->
      <div v-if="$route.name == 'ActivityListView'" v-on:click="$router.push('/details')" class="svgcontainer">
        <svg xmlns="http://www.w3.org/2000/svg" enable-background="new 0 0 24 24" height="20px" viewBox="0 0 24 24"
          width="20px" fill="#ffffff">
          <g>
            <rect fill="none" height="24" width="24" x="0" />
          </g>
          <g>
            <g>
              <path
                d="M20.5,2v2.02C18.18,2.13,15.22,1,12,1S5.82,2.13,3.5,4.02V2H2v3.5V7h1.5H7V5.5H4.09c2.11-1.86,4.88-3,7.91-3 s5.79,1.14,7.91,3H17V7h3.5H22V5.5V2H20.5z" />
              <g>
                <path
                  d="M18.89,13.77l-3.8-1.67C14.96,12.04,14.81,12,14.65,12H14l0-4.37c0-1.32-0.96-2.5-2.27-2.62C10.25,4.88,9,6.05,9,7.5 v8.15l-1.87-0.4c-0.19-0.03-1.02-0.15-1.73,0.56L4,17.22l5.12,5.19C9.49,22.79,10,23,10.53,23h6.55c0.98,0,1.81-0.7,1.97-1.67 l0.92-5.44C20.12,15.03,19.68,14.17,18.89,13.77z M18,15.56L17.08,21h-6.55l-3.7-3.78L11,18.11V7.5C11,7.22,11.22,7,11.5,7 S12,7.22,12,7.5v6.18h1.76L18,15.56z" />
              </g>
            </g>
          </g>
        </svg>
      </div>

      <!-- Share Favorites -->
      <div v-if="
        $route.name == 'FavoritesListView' &&
        $store.state.storeActivityListFavorites.length != 0
      " v-on:click="sharebuttonclick()" class="svgcontainer">
        <svg xmlns="http://www.w3.org/2000/svg" height="20px" viewBox="0 0 24 24" width="20px" fill="#ffffff">
          <path d="M0 0h24v24H0V0z" fill="none" />
          <path
            d="M18 16.08c-.76 0-1.44.3-1.96.77L8.91 12.7c.05-.23.09-.46.09-.7s-.04-.47-.09-.7l7.05-4.11c.54.5 1.25.81 2.04.81 1.66 0 3-1.34 3-3s-1.34-3-3-3-3 1.34-3 3c0 .24.04.47.09.7L8.04 9.81C7.5 9.31 6.79 9 6 9c-1.66 0-3 1.34-3 3s1.34 3 3 3c.79 0 1.5-.31 2.04-.81l7.12 4.16c-.05.21-.08.43-.08.65 0 1.61 1.31 2.92 2.92 2.92s2.92-1.31 2.92-2.92c0-1.61-1.31-2.92-2.92-2.92zM18 4c.55 0 1 .45 1 1s-.45 1-1 1-1-.45-1-1 .45-1 1-1zM6 13c-.55 0-1-.45-1-1s.45-1 1-1 1 .45 1 1-.45 1-1 1zm12 7.02c-.55 0-1-.45-1-1s.45-1 1-1 1 .45 1 1-.45 1-1 1z" />
        </svg>
      </div>
    </button>
  </div>
</template>

  <!-- ---------- ---------- ---------- Code ---------- ---------- ---------- -->

<script>
/* eslint-disable */

export default {
  components: {},
  data() {
    return {
      tweenedNumber: this.$store.state.storeActivityListFiltered.length,
    };
  },
  watch: {
    activityCount() {
      gsap.to(this.$data, { duration: 0.6, tweenedNumber: this.activityCount });
    },
  },
  computed: {
    activityCount() {
      return this.$store.state.storeActivityListFiltered.length;
    },
    animatedNumber() {
      return this.tweenedNumber.toFixed(0);
    },
  },
  methods: {
    sharebuttonclick() {
      var favoriteString;
      var i = 0;
      var that = this;
      var shareText;
      this.$store.state.storeActivityListFavorites.forEach(function (activity) {
        if (i == 0) {
          favoriteString =
            activity[
            that.$store.state.appTexts.activitynamehelper[
            that.$store.state.user.countryCode
            ]
            ];
        } else {
          favoriteString =
            favoriteString +
            ", " +
            activity[
            that.$store.state.appTexts.activitynamehelper[
            that.$store.state.user.countryCode
            ]
            ];
        }
        i++;
      });
      if (this.$store.state.user.countryCode == "DE") {
        shareText =
          "Hey, ich habe gerade ein paar Aktivitäten für uns auf Timetip gefunden. Hast du auf eine davon lust? " +
          favoriteString +
          " - ";
        console.log(shareText);
      } else {
        shareText =
          "Hey, I just found a few activities for us on Timetip. Are you interested in one of them? " +
          favoriteString +
          " - ";
        console.log(shareText);
      }

      if (navigator.share) {
        navigator
          .share({
            title: document.title + " App",
            text: shareText,
            url: "https://www.timetip.app/",
          })
          .then(() => console.log("Successful share"))
          .catch((error) => console.log("Error sharing:", error));
      }
    },
  },
};
</script>

<style scoped>
.welcome {
  font-family: "Comic Sans MS";
  font-family: "DancingScript-Regular";
  font-size: 20px;
  font-style: normal;
  font-variant: normal;
  font-weight: 400;
  /*background-image: url("../assets/background.jpg");
  background-size: cover;*/
  font-weight: bold;
}

.svgcontainer {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

#activitycount {
  color: #ffffff;
  /*font-weight: bold;*/
  text-decoration: none;
}

.navcontainer {
  display: flex;
  align-content: space-between;
  /* box-shadow: 0px 0px 3px #dee2e6; */

}

.navtab {
  background-color: transparent;
  border: none;
  outline: none;
  padding-top: 0em;
  padding-bottom: 0em;
  padding-left: 0rem;
  padding-right: 0rem;
  vertical-align: middle;
  height: 100%;
  background-color: #007bff;
  color: #ffffff;
  font-weight: normal;
  pointer-events: all;
}

.navtab1 {
  display: inline;
  width: 12.5%;
  cursor: pointer;
}

.navtab2 {
  display: inline;
  width: 75%;
  text-align: center;
}

.navtab3 {
  display: inline;
  width: 12.5%;
  text-align: center;
}

.iconwrapper {
  padding-top: 0.5em;
  padding-bottom: 0.5em;
}

.icon {
  pointer-events: none;
}

/*ohnne enter-to passiert hier nichts */
.onCountChange-enter-active {
  transition: all 0.8s ease;
}

.onCountChange-leave-active {
  transition: all 0.4s ease;
}

/*enter und leave sind wohl immer vertauscht*/
.onCountChange-leave-to {
  transform: translateX(10px);
  opacity: 0.2;
}

.dancing10 {
  font-family: 'DancingScript-Regular', cursive;
  font-size: 1.0rem;
  display: inline;
}

.dancing12 {
  font-family: 'DancingScript-Regular', cursive;
  font-size: 1.2rem;
  display: inline;
}

.dancing14 {
  font-family: 'DancingScript-Regular', cursive;
  font-size: 1.4rem;
  display: inline;

}
</style>