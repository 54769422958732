<template>
  <!-- Vuetify -->
  <v-app>
    <v-main>
      <NavigationTopComponent class="header" />
      <div
        style="position: fixed; border-top: 1px solid #dee2e6; bottom: 0rem; width: 100%; z-index: 100; background-color: #ffffff;padding-bottom: 0rem;"
        v-bind:class="{ iphonefooter: this.$store.state.browserName == 'safari' && safariStandalone }">
        <NavigationBottomComponent style="height: 3.5rem; display: flex; justify-content: space-around;"
          v-if="$store.state.bootingDone" />
      </div>
      <!-- BUG maybe here at firstVisit because it may be set too late -->
      <div v-if="
        !(
          // activitySelected &&
          $store.state.dataLoaded &&
          $store.state.linksLoaded &&
          $store.state.bootingDone
        )
      " v-on:click="clickOnWelcomePage()" class="loading">
        <LoadingScreenComponent />
      </div>

      <!--Content of the app-->
      <div v-else class="content">
        <div class="bootingDoneClass" v-if="$store.state.bootingDone">
          <router-view v-slot="{ Component, route }">
            <transition :name="route.meta[animationClass]" :mode="route.meta[animationMode]">
              <component :is="Component" />
            </transition>
          </router-view>
        </div>
        <DetailsHeartComponentSnackbar />
        <FilterSnackbar />
        <LegalFooterComponent class="legalfooter" v-bind:class="{
          legalfooterdatanotloaded:
            !$store.state.dataLoaded ||
            ($route.name == 'DetailsView' && !activitySelected),
        }" v-if="$route.name != 'FilterView'" />
      </div>
    </v-main>
  </v-app>
</template>

<script>
/* eslint-disable */

console.log("App.vue wird erstellt");
// Hole alle Activities aus dem Google Spreadsheet
import GetActivitiesService from "@/services/GetActivitiesService.js";
import GetLinksService from "@/services/GetLinksService.js";
import GetSpicesService from "@/services/GetSpicesService.js";
import GetFiltersService from "@/services/GetFiltersService.js";
import GetAppTextsService from "@/services/GetAppTextsService.js";
import GetWeatherData from "@/services/GetWeatherData.js";
import GetDate from "@/services/GetDate.js";

import UpdateActivitiesService from "@/services/UpdateActivitiesService.js";
import UpdateFavoritesService from "@/services/UpdateFavoritesService.js";
import AddToHomeScreenService from "@/services/AddToHomeScreenService.js";


import NavigationTopComponent from "@/components/Navigation/NavigationTopComponent.vue";
import NavigationBottomComponent from "@/components/Navigation/NavigationBottomComponent.vue";
import LegalFooterComponent from "@/components/Navigation/LegalFooterComponent.vue";
import LoadingScreenComponent from "@/components/Navigation/LoadingScreenComponent.vue";
import DetailsHeartComponentSnackbar from "@/components/Details/DetailsHeartComponentSnackbar.vue";
import FilterSnackbar from "@/components/Filters/FilterSnackbar.vue";

import FireStoreService from "@/firebase/FireStoreService.js";

export default {
  components: {
    NavigationTopComponent,
    NavigationBottomComponent,
    LegalFooterComponent,
    LoadingScreenComponent,
    GetFiltersService,
    GetAppTextsService,
    DetailsHeartComponentSnackbar,
    FilterSnackbar
  },
  data() {
    return {};
  },
  beforeCreate() {
    // Detect Touch Device
    this.$store.state.isTouchDevice =
      "ontouchstart" in document.documentElement;
    console.log("isTouchDevice: " + this.$store.state.isTouchDevice);
    // Detect Browser
    let userAgent = navigator.userAgent;
    let browserName;

    if (userAgent.match(/chrome|chromium|crios/i)) {
      browserName = "chrome";
    } else if (userAgent.match(/firefox|fxios/i)) {
      browserName = "firefox";
    } else if (userAgent.match(/safari/i)) {
      browserName = "safari";
    } else if (userAgent.match(/opr\//i)) {
      browserName = "opera";
    } else if (userAgent.match(/edg/i)) {
      browserName = "edge";
    } else {
      browserName = "No browser detection";
    }
    this.$store.state.browserName = browserName;
    console.log("browserName: " + browserName);

    // Get GeoLocation
    // https://www.geoplugin.com/quickstart
    if (typeof geoplugin_city == "function") {
      if (geoplugin_city()) {
        this.$store.state.city = geoplugin_city()
      }
      else { this.$store.state.city = "Frankfurt" }
      console.log("geoplugin city: " + this.$store.state.city)
    }
    else {
      this.$store.state.city = "Frankfurt"
      console.log("geoplugin ERROR | city: " + this.$store.state.city)
    }

    if (localStorage.getItem("countryCode") == null) {
      if (navigator.language.toLowerCase().search("de") >= 0) {
        this.$store.state.user.countryCode = "DE";
        console.log("countryCode is set to 'DE' according to Browser Language");
      } else {
        this.$store.state.user.countryCode = "DE";
        console.log(
          "countryCode is set to 'DE' although the Browser Language is not german"
        );
        // this.$store.state.user.countryCode = "EN";
        // console.log("countryCode is set to 'EN' according to Browser Language");
      }
      localStorage.setItem("countryCode", this.$store.state.user.countryCode);
    } else {
      this.$store.state.user.countryCode = localStorage.getItem("countryCode");
      console.log("countryCode retrieved from localStorage");
    }

    // Todo: Check, if getLinks causes image loading problems
    GetLinksService.getLinks();
    GetAppTextsService.getAppTexts();
    // GetSpicesService.getSpices();
    GetDate.getDate();
    GetWeatherData.getWeatherData();

    let that = this; //   create a reference to the "this" variable from this context out here
    // Has to be the first function before GetActivities, UpdateActivities
    GetFiltersService.getFilters().then(function (filter) {
      console.log("BBBBBBBBBB");
      GetActivitiesService.getActivities().then(function () {
        console.log("CCCCCCCCCC");
        // I think the followin two are not needed
        //calculate the "count" of all filters and set storeActivityListFiltered
        UpdateActivitiesService.updateActivities().then(function () {
          console.log("DDDDDDDDDD");
          console.log("all starting services executed (bootingDone = true)");
          that.$store.state.storeSelectedActivityId = that.$store.state.storeActivityList[0].id
          that.$store.state.activity = that.$store.state.storeActivityList[0]
          that.$store.state.bootingDone = true;
        });
      });
    });
    console.log("AAAAAAAAAA");
  },
  mounted() {
    // Updating the User Data before leaving the App does not really work
    // window.addEventListener("beforeunload", (e) => {
    //   if (this.$store.state.nutzer) {
    //     FireStoreService.setFS();
    //     console.log("Timetip closed.");
    //     console.log("Favorites saved to Backend");
    //   } else {
    //     console.log("Timetip closed.");
    //   }
    // });

    // Keep User synchronous between tabs by watching userLoggedIn
    window.addEventListener("storage", () => {
      // Keep nutzer synchronous
      //Check, if there already is an Entry about userLoggedIn in the local Storage. If not, do nothing
      if (localStorage.getItem("userLoggedIn") != null) {
        console.log(userFromLS);
        console.log("XXXXX localStorage changed and userLoggedIn != 'null'");
        var userFromLS = JSON.parse(localStorage.getItem("user"));
        var userLoggedIn = localStorage.getItem("userLoggedIn");
        // If localStorage says User is not logged in, but there is a user Object in the Store --> Delete user from store
        if (userLoggedIn == "false" && this.$store.state.nutzer) {
          this.$store.state.nutzer = null;
          console.log("XXXXX Deleted user from store");
        }
        // If localStorage says User is logged in, but there is no user Object in the Store --> Add user to store (from localStorage)
        if (userLoggedIn == "true" && !this.$store.state.nutzer) {
          this.$store.state.nutzer = userFromLS;
          console.log("XXXXX Added user to store (from localStorage)");
        }
      }
      // Keep Favorites synchronous between tabs
      // !!!!!! Only enable, if user is not logged in - otherwise this is done by the Firestore Sync
      if (!this.$store.state.nutzer) {
        if (localStorage.getItem("favoritesObject") != null) {
          var favoritesObjectFromLS = JSON.parse(
            localStorage.getItem("favoritesObject")
          );
          if (this.$store.state.favoritesObject != favoritesObjectFromLS) {
            this.$store.state.favoritesObject = favoritesObjectFromLS;
            console.log("FFFFF Added favorites to store (from localStorage)");
            UpdateFavoritesService.updateFavorites("none");
          }
        }
      }
    });
    // Capture Add to homescreen
    AddToHomeScreenService.captureEvent();

    // Deactivate Usercentrics Fingerprint
    // Add an observer for the dom  https://developer.mozilla.org/en-US/docs/Web/API/MutationObserver?retiredLocale=de
    const targetNode = document.body
    const config = { childList: true };
    // Callback function to execute when mutations are observed
    const callback = (mutationList, observer) => {
      for (const mutation of mutationList) {
        if (mutation.type === 'childList') {
          // console.log('A child node has been added or removed.');

          // Check, if id usercentrics-root exists
          if (document.getElementById("usercentrics-root")) {
            console.log("fingerprint display: none")
            var us = document.getElementById("usercentrics-root").shadowRoot.children[0]
            var css = '.jEcacy {display:none !important;}'
            var style = document.createElement('style');
            style.appendChild(document.createTextNode(css))
            us.appendChild(style)

            // Later, you can stop observing
            observer.disconnect();
          }
          else console.log("???")
        }
      }
    };
    // Create an observer instance linked to the callback function
    const observer = new MutationObserver(callback);
    // Start observing the target node for configured mutations
    observer.observe(targetNode, config);
  },
  methods: {},
  watch: {
    userLoggedIn() {
      // Timeout needed so that the favorites can be compared first | TODO: change Timeout to Async or so
      setTimeout(function () {
        console.log("UUUUU userLoggedIn watch executed");
        var x = FireStoreService.listenForFSChanges();
        console.log(x);
      }, 200);
    },
  },
  computed: {
    safariStandalone() {
      return window.navigator.standalone
    },
    userLoggedIn() {
      if (this.$store.state.nutzer) {
        console.log("UUUUU this.$store.state.nutzer changed");
        return this.$store.state.nutzer;
      }
    },
    animationClass() {
      if (this.$store.state.filterClicked) {
        return "animationClass2";
      } else return "animationClass";
    },
    animationMode() {
      if (this.$store.state.filterClicked) {
        return "animationMode2";
      } else return "animationMode";
    },
    // https://developer.mozilla.org/en-US/docs/Web/API/Navigator
    // navigator.geolocation
    // navigator.share()
    // navigator.vibrate()

    activitySelected() {
      if (this.$store.state.storeSelectedActivityId >= 0) {
        return true;
      } else {
        return false;
      }
    },
  },
};
</script>

<style scoped>
/* switch transitions */
.splash-enter-from,
.splash-leave-to {
  opacity: 0;
  /* transform: translateY(100vh); */
}

.splash-enter-active {
  transition: all 0.5s ease;
}

.splash-leave-active {
  transition: all 0s ease;
}

.splash-leave-from {
  opacity: 0;
}

/* bottomup transitions */
.bottomup-enter-from {
  transform: translateY(100vh);
}

.bottomup-enter-active {
  transition: all 0.3s ease-in-out;
}

/* topdown transitions */
.topdown-leave-to {
  transform: translateY(100vh);
}

.topdown-leave-active {
  transition: all 0.3s ease-in-out;
}

.header {
  position: fixed;
  top: 0;
  height: 40px;
  width: 100%;
  z-index: 300;
  overflow: hidden;
}

.iphonefooter {
  padding-bottom: env(safe-area-inset-bottom, 1rem) !important;
}

.content {
  /*Todo: maybe change to margin-top and bottom */
  padding-top: 2.5rem;
  padding-bottom: 3.5rem;
  z-index: 50;
  background-color: #ffffff;
  /*This is necessary so that Android Chrome does not automatically adjust the font size */
  /*https://stackoverflow.com/questions/11289166/chrome-on-android-resizes-font */
  min-height: 100vh;
  max-height: 999999px;
}

.loading {
  position: fixed;
  height: 100vh;
  width: 100%;
  background-color: #007bff;
  z-index: 301;
}

.legalfooter {
  position: relative;
  z-index: 90;
  padding-top: 2rem;
  padding-bottom: 6rem;
  border-top: 1px solid #dee2e6;
}

.legalfooterdatanotloaded {
  width: 100%;
  position: fixed;
  bottom: 0;
  z-index: 90;
  margin-top: 1rem;
  padding-bottom: 6rem;
  background: none;
}
</style>

<!--Style not Scoped!!!!!!-->
<style>
@font-face {
  font-family: 'Nunito-Regular';
  src: url('assets/fonts/Nunito-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'DancingScript-Regular';
  src: url('assets/fonts/DancingScript-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

html,
body {
  overscroll-behavior-y: none;
  /* overscroll-behavior: contain; */
  font-family: 'Nunito-Regular', sans-serif;
  height: 100vh;
}

.dancing {
  font-family: 'DancingScript-Regular'
}

.filterviewclass {
  position: absolute;
  top: 40px;
  width: 100%;
}

#app {
  /*font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;*/
  text-align: center;
  color: #2c3e50;
}

* {
  /*Disable Double-tap zoom */
  touch-action: manipulation;
  box-sizing: border-box !important;
}

.comic2 {
  font-family: "DancingScript-Regular";
  font-size: 18px;
  font-style: normal;
  font-variant: normal;
}

.space {
  padding: 1rem 0rem 1rem;
}

.spacebelow {
  padding: 0rem 0rem 1rem;
}

.favoriteselected {
  color: red;
}

.list-title {
  font-weight: bold;
  color: #000000;
  margin-block-start: 0em;
  margin-block-end: 0.5em;
}

.textbox {
  padding: 1em;
  border-radius: 0.25rem;
  background-color: #007bff20;
  margin-left: 1rem;
  margin-right: 1rem;
}

.textboxleft {
  padding: 1em;
  border-radius: 0.25rem;
  background-color: #007bff20;
  text-align: left;
}

.notselected {
  color: #ffffff;
  background-color: #20c99780;
  /*;*/
}

.selected {
  color: #ffffff;
  background-color: #20c997;
  /*#e83e8c;*/
  pointer-events: all;
}

.notselected2 {
  color: #ffffff;
  background-color: #fd7e1480;
}

.selected2 {
  color: #ffffff;
  background-color: #fd7e14;
  pointer-events: all;
}

.notselected3 {
  color: #ffffff;
  background-color: #e83e8c80;
}

.selected3 {
  color: #ffffff;
  background-color: #e83e8c;
  pointer-events: all;
}

.notselected4 {
  color: #ffffff;
  background-color: #6610f280;
}

.selected4 {
  color: #ffffff;
  background-color: #6610f2;
  pointer-events: all;
}

.notselected5 {
  color: #ffffff;
  background-color: #0dcaf080;
  /*;*/
}

.selected5 {
  color: #ffffff;
  background-color: #0dcaf0;
  /*#e83e8c;*/
  pointer-events: all;
}

.notselected6 {
  color: #ffffff;
  background-color: #7FBDFF
}

.selected6 {
  color: #ffffff;
  background-color: #007bff;
  /*#e83e8c;*/
  pointer-events: all;
}

.notselected7 {
  color: #ffffff;
  background: #7fbdff;
  /*;*/
}

.selected7 {
  color: #ffffff;
  background: #007bff;
  /*#e83e8c;*/
  pointer-events: all;
}



.countZero {
  color: #ffffff;
  background-color: #f1f1f1;
  /*#dee2e6;*/
  pointer-events: none;
  background-image: none;

}

.lastanswerwithvalues {
  background-color: #8a8a8a;
  pointer-events: none;
  background-image: none;

}

.alignleft {
  text-align: left;
  margin-left: 1rem;
}

button {
  /* border: none; */
}

button:focus {
  outline: none;
}

/* usercentrics adaptions */
.usercentrics-button .uc-button {
  display: none;
}

.uc-banner-content {
  background-color: #ffffff !important;
}

.uc-optin-description {
  color: #000000 !important;
  text-align: center !important;
}

.usercentrics-button.layout-1 .uc-banner-content .btn-list .uc-btn {
  border-radius: 0.6rem !important;
}

.btn-deny {
  background-color: #007bff80 !important;
  color: white !important;
}

.uc-btn-primary {
  background-color: #007bff !important;
  /*rgb(0, 123, 255)  */
  color: white !important;
}

.more-info-button {
  background-color: #007bff80 !important;
  color: white !important;
}

.uc-btn-footer-container {
  text-align: center !important;
}

.uc-powered-by-footer {
  padding-top: 12px !important;
}

/* usercentrics end */
.n-drawer-container {
  top: unset !important;
  height: 100vh !important;
  touch-action: pan-y !important;
}

.n-drawer-body-content-wrapper {
  touch-action: pan-y !important;
}

/* .n-drawer.n-drawer--bottom-placement {
}
.n-drawer-body {
}
.n-drawer {
}
.n-drawer-content-wrapper {
}
.n-drawer-content {
}
.n-drawer-mask {
}
.n-drawer--native-scrollbar {
} */
.v-binder-follower-container {
  z-index: 1000 !important;
}
</style>

